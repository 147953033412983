import React from "react";
import SEO from "../components/seo";
import Layout from "../components/layout";
import Container from "../components/container";

const KontaktDziekujemy = ({ data }) => {
  return (
    <Layout
      title="Dziękujemy"
      headline="za zainteresowanie naszymi rozwiązaniami"
    >
      <SEO
        title="Dziękujemy za zainteresowanie naszymi rozwiązaniami"
        description="Kontakt ✅ Skontaktujemy się z Tobą, tak szybko, jak to możliwe • Questy - Twój biznes wygrywa"
        keywords={["kontakt"]}
      />
      <Container>
        <div
          style={{
            marginTop: 100,
          }}
        >
          <h1
            style={{
              fontSize: 24,
              marginBottom: 20,
              marginTop: 100,
            }}
          >
            Dziękujemy
            <br />
            za zainteresowanie naszymi rozwiązaniami
          </h1>
        </div>
        <div>
          <p>
            Skontaktujemy się z Tobą tak szybko, jak to tylko możliwe –
            najpóźniej do końca następnego dnia roboczego. Chcemy zaoferować Ci
            prezentację systemu oraz pomóc w określeniu tego, co w największym
            stopniu wpłynie na usprawnienie pracy w Twojej firmie.{" "}
          </p>
          <p>
            Zobacz, co publikujemy na portalu{" "}
            <strong>
              <a href="https://www.linkedin.com/company/4859594/admin/">
                {" "}
                Linkedin
              </a>{" "}
            </strong>{" "}
            oraz
            <strong>
              {" "}
              <a href="https://www.facebook.com/questysoftware/"> Facebook</a>
            </strong>
            .
          </p>
        </div>
      </Container>
    </Layout>
  );
};

export default KontaktDziekujemy;
